<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" @change="(event) => updateMachTypeDropdown(event)" :return-object="true" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="divisionModel" :items="divisionLists" default="" item-value="class" item-text="descr" label="Select Division" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Select Mach. Type" @change="(event) => updateMachIDDropdown(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machIDModel" :items="machIDLists" default="" item-value="mach_id" item-text="descr" label="Select Mach. ID" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-12">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph2">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph3">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer3" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph4">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer4" style="height: 423px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 80%;">
                    <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12 graphDetail">
                                <div id="chartContainer5" style="height: 423px; width: 100%;"></div>
                            </div>
                            <div class="col-lg-12">
                                <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense></v-data-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Production Performance',
                disabled: false,
                href: '#',
                },
                {
                text: 'Overall Equipment Effectiveness (OEE)',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            divisionModel: '',
            divisionLists: [],
            machTypeModel: '',
            machTypeLists: [],
            machIDModel: '',
            machIDLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false,
            headersDetails: [],
            itemDetailLists: [],
            loadingDatatableDetail: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OverallEquipmentEffectiveness`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.divisionLists = res.data.division
                this.machTypeLists = res.data.machType
                this.machIDLists = res.data.machID
                this.$store.dispatch('setOverlay', false)
            })

        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";
            
            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OverallEquipmentEffectiveness/getChartData`, { 

                mill: this.millModel ? this.millModel.mill_id : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                dateTo: this.dateToFormatted ? this.dateToFormatted : '',

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.arr_list1.length != 0){
                    document.querySelector(".cardGraph1").style.display = "block";
                    document.querySelector(".cardGraph2").style.display = "block";
                    document.querySelector(".cardGraph3").style.display = "block";
                    document.querySelector(".cardGraph4").style.display = "block";
                    this.renderChart1(res.data.arr_list1, this.millModel.mill_name, 90, 91)
                    this.renderChart2(res.data.arr_list2, this.millModel.mill_name, 95, 96)
                    this.renderChart3(res.data.arr_list3, this.millModel.mill_name, 99.95, 100.95)
                    this.renderChart4(res.data.arr_list4, this.millModel.mill_name, 85, 86)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph1").style.display = "none";
                    document.querySelector(".cardGraph2").style.display = "none";
                    document.querySelector(".cardGraph3").style.display = "none";
                    document.querySelector(".cardGraph4").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }

            })

        },

        async updateMachTypeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/QualityPerformance/getMachineByMill?mill=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/QualityPerformance/getMachineByMill?mill=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            }
        },

        async updateMachIDDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/QualityPerformance/getMachineByID?mill=${this.millModel}&machType=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.machID
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/gbrk/QualityPerformance/getMachineByID?mill=all&machType=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machIDLists = []
                    this.machIDLists = res.data.machID
                })
            }
        },

        renderChart1(data1, mill, th, thplus){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: "Availabilty Ratio",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: mill,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    maximum: 150,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "%",
                    labelFormatter: this.addSymbols,
                    stripLines: [{
                        showOnTop: true,
                        lineDashType: "dash",
                        color: "#d8d8d8",
                        labelFontColor: "#0c4271",
                        labelFontSize: 14,
                        startValue: th,
                        endValue: thplus,
                        label: th+'%'

                    }]
                },
                toolTip:{
                    shared: true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "line",
                        click: this.availabilityRatioDetail,
                        showInLegend: true,
                        name: "AvailabilityRatio",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data1
                    }
                ]
            })

            chart1.render()

        },

        renderChart2(data2, mill, th, thplus){

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: "Performance Efficiency",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: mill,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    maximum: 150,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "%",
                    labelFormatter: this.addSymbols,
                    stripLines: [{
                        showOnTop: true,
                        lineDashType: "dash",
                        color: "#d8d8d8",
                        labelFontColor: "#0c4271",
                        labelFontSize: 14,
                        startValue: th,
                        endValue: thplus,
                        label: th+'%'

                    }]
                },
                toolTip:{
                    shared: true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "line",
                        click: this.performanceEfficiencyDetail,
                        showInLegend: true,
                        name: "PerformanceEfficiency",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data2
                    }
                ]
            })

            chart2.render()

        },

        renderChart3(data3, mill, th, thplus){

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: "Rate Of Quality",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: mill,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    maximum: 150,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "%",
                    labelFormatter: this.addSymbols,
                    stripLines: [{
                        showOnTop: true,
                        lineDashType: "dash",
                        color: "#d8d8d8",
                        labelFontColor: "#0c4271",
                        labelFontSize: 14,
                        startValue: th,
                        endValue: thplus,
                        label: th+'%'

                    }]
                },
                toolTip:{
                    shared: true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "line",
                        click: this.rateOfQualityDetail,
                        showInLegend: true,
                        name: "RateOfQuality",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data3
                    }
                ]
            })

            chart3.render()

        },

        renderChart4(data4, mill, th, thplus){

            var chart4 = new CanvasJS.Chart("chartContainer4", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: "Overall Equipment Effectiveness",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: mill,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    maximum: 150,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "%",
                    labelFormatter: this.addSymbols,
                    stripLines: [{
                        showOnTop: true,
                        lineDashType: "dash",
                        color: "#d8d8d8",
                        labelFontColor: "#0c4271",
                        labelFontSize: 14,
                        startValue: th,
                        endValue: thplus,
                        label: th+'%'

                    }]
                },
                toolTip:{
                    shared: true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "line",
                        click: this.OEEDetail,
                        showInLegend: true,
                        name: "OEE",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data4
                    }
                ]
            })

            chart4.render()

        },

        renderChart5(data5, title, label, chartName, th, thplus){

            var chart5 = new CanvasJS.Chart("chartContainer5", {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: false,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: label,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    // maximum: 150,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "%",
                    labelFormatter: this.addSymbols,
                    stripLines: [{
                        showOnTop: true,
                        lineDashType: "dash",
                        color: "#d8d8d8",
                        labelFontColor: "#0c4271",
                        labelFontSize: 14,
                        startValue:th,
                        endValue:thplus,
                        label:th+'%'
                    }]
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "line",
                        // click: aa,
                        showInLegend: true,
                        name: chartName,
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        dataPoints: data5
                    }
                ]
            })

            chart5.render()

        },

        async availabilityRatioDetail(e){

            document.querySelector(".graphDetail").style.display = "none";

            this.headersDetails = [
                { text: 'PERIODE', value: 'Periode', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MILL ID', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TYPE', value: 'mach_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'FULLTIME(Hourly)', value: 'fulltime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DOWNTIME(Hourly)', value: 'downtime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RUNTIME(Hourly)', value: 'runtime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RATIO(%)', value: 'ratio', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.itemDetailLists = []

            this.loadingDatatableDetail = true

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OverallEquipmentEffectiveness/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel.mill_id : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.label,
                type: "availabilityRatio"

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                document.querySelector(".graphDetail").style.display = "block";
                this.renderChart5(res.data.arr_list1, "Availability Ratio", this.millModel.mill_name + ' ' + e.dataPoint.label, "Availability Ratio", 90, 91)
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false

            })

        },

        async performanceEfficiencyDetail(e){

            document.querySelector(".graphDetail").style.display = "none";

            this.headersDetails = [
                { text: 'PERIODE', value: 'Periode', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MILL ID', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TYPE', value: 'mach_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL PROD.(M)', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PPIC PLAN(M)', value: 'target', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'FULLTIME(Hourly)', value: 'fulltime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DOWNTIME(Hourly)', value: 'downtime', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.itemDetailLists = []

            this.loadingDatatableDetail = true

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OverallEquipmentEffectiveness/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel.mill_id : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.label,
                type: "performanceEfficiency"

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                document.querySelector(".graphDetail").style.display = "block";
                this.renderChart5(res.data.arr_list1, "Performance Efficiency", this.millModel.mill_name + ' ' + e.dataPoint.label, "Performance Efficiency", 95, 95)
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false

            })

        },

        async rateOfQualityDetail(e){

            document.querySelector(".graphDetail").style.display = "none";

            this.headersDetails = [
                { text: 'PERIODE', value: 'Periode', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MILL ID', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TYPE', value: 'mach_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL PROD.(M)', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'NON PRIME', value: 'nonprime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RATE(%)', value: 'rate', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.itemDetailLists = []

            this.loadingDatatableDetail = true

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OverallEquipmentEffectiveness/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel.mill_id : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.label,
                type: "rateOfQuality"

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                document.querySelector(".graphDetail").style.display = "block";
                this.renderChart5(res.data.arr_list1, "Rate Of Quality", this.millModel.mill_name + ' ' + e.dataPoint.label, "Rate Of Quality", 99.95, 100.95)
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false

            })

        },

        async OEEDetail(e){

            document.querySelector(".graphDetail").style.display = "none";

            this.headersDetails = [
                { text: 'PERIODE', value: 'Periode', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MILL ID', value: 'mill_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TYPE', value: 'mach_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL PROD.(M)', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'NON PRIME', value: 'nonprime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PPIC PLAN(M)', value: 'target', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'FULLTIME(Hourly)', value: 'fulltime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DOWNTIME(Hourly)', value: 'downtime', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RUNTIME(Hourly)', value: 'runtime', align: 'right', class: 'primary--text blue lighten-5' }
            ]

            this.itemDetailLists = []

            this.loadingDatatableDetail = true

            $('#detailModal').modal('show')

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OverallEquipmentEffectiveness/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel.mill_id : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                period: e.dataPoint.label,
                type: "OEE"

            },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                document.querySelector(".graphDetail").style.display = "block";
                this.renderChart5(res.data.arr_list1, "OEE", this.millModel.mill_name + ' ' + e.dataPoint.label, "OEE", 85, 86)
                this.itemDetailLists = res.data.result
                this.loadingDatatableDetail = false

            })

        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}`;      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>